import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MAX_AVAILBILITY_DATES_TO_SHOW } from 'src/containers/BookingPage/SummaryPage/constants';
import OrderSummaryLineItem from 'src/components/OrderSummary/Breakdown/OrderSummaryLineItem';

const CartAvailabilityAccordion = ({ times }) => {
  if (!times) return null;
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  const getTimesToShow = useCallback(() => {
    if (times.length <= MAX_AVAILBILITY_DATES_TO_SHOW) return times;
    return showMore ? times : times.slice(0, MAX_AVAILBILITY_DATES_TO_SHOW);
  }, [showMore]);

  const timesToShow = getTimesToShow();
  const timesRemaining = times.length - MAX_AVAILBILITY_DATES_TO_SHOW;
  const showSeeMore = times.length > MAX_AVAILBILITY_DATES_TO_SHOW;
  const seeMoreText = showMore ? 'See Less' : `+ ${timesRemaining} more`;

  return (
    <>
      {timesToShow.map((time) => (
        <OrderSummaryLineItem key={time}>{time}</OrderSummaryLineItem>
      ))}
      {showSeeMore && (
        <div className="table-row text-link" onClick={toggleShowMore}>
          {seeMoreText}
        </div>
      )}
    </>
  );
};

CartAvailabilityAccordion.propTypes = {
  times: PropTypes.array,
  formatDate: PropTypes.func,
  toggleAvailability: PropTypes.func,
  expandAvailability: PropTypes.bool,
};

CartAvailabilityAccordion.defaultProps = { times: [] };

export default memo(CartAvailabilityAccordion);

/*
  This isn't a general use component and is meant to work with the data returned from cart.get('consolidatedAvailability')

  Example of data it expects: {"2020-06-07":["9am - 8pm"],"2020-06-08":["1pm","6pm"]}
*/
