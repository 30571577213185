/* Import any default config dependencies */
import get from 'lodash/get';
import { PROMO_BAR_CONFIG_DEFAULTS } from 'src/components/PromoBar/constants';
import { BANNER_RADIO_OFFER_DEFAULTS } from 'src/components/Banners/Radio/RadioOfferBanner';
import { UPSELL_PLAN_CONTENT_DEFAULTS } from 'src/components/HTKit/Modals/MembershipUpsellModal/constants';
import HT_INFO from 'src/constants/hellotech';

/**
 * TWO STEP PROCESS ADDING & REMOVING
 * 1. You added a new split to the splitio admin, add it here *VERBATIM*
 * 2. Add to the array of control defaults
 * 3. IF your split has a config and you want a default, a "control" treatment, import it from the the source area and add below
 */
/* STEP 1: These are our specific references to the SPLIT KEYS (set in spit). They must match. */
export const SPLITIONAME_PROMO_BAR = 'promo_bar';
export const SPLITIONAME_NAV_PHONE_NUMBER = 'nav_phone_number';
export const SPLITIONAME_INTERCOM_CHAT_MODULE = 'intercom_chat_module';
export const SPLITIONAME_MEMBER_BENEFITS_BUTTON_CHAT_MODULE = 'memberbenefits_chat_module_button';
export const SPLITIONAME_POPULAR_ONLINE_SERVICES = 'popular_online_services';
export const SPLITIONAME_BANNER_RADIO_OFFER = 'banner_radio_offer';
export const SPLITIONAME_HOMEPAGE_SEARCH = 'homepage_search';
export const SPLITIONAME_PLAN_PRICING = 'plan_pricing';
export const SPLITIONAME_LOW_TIER_MEMBERSHIP_V1 = 'low_tier_membership_v1';
export const SPLITIONAME_PRODUCT_SPECIALIST_CHAT = 'product_specialist_chat';
export const SPLITIONAME_CART_PRODUCT_INSTALLATION_UPSELL = 'cart_product_installation_upsell';
export const SPLITIONAME_WALMART_PLAN_UPSELL = 'walmart_plan_upsell';
export const SPLITIONAME_ORDER_CONFIRMATION_PAGE_LANGUAGE = 'order_confirmation_page_language';
export const SPLITIONAME_MYACCOUNT_CANCEL_ORDER_CALL_MODAL = 'myaccount_cancel_order_call_modal';
export const SPLITIONAME_ORDER_CONFIRMATION_PAGE_UPSELL =
  'order_confirmation_page_membership_upsell';
export const SPLITIONAME_LP_MEMBERSHIP_TEST = 'lp_membership_test';
export const SPLITIONAME_PRODUCT_PAGE_MEMBERSHIP_UPSELL = 'product_page_membership_upsell';
export const SPLITIONAME_HOMEPAGE_IFRAME = 'homepage_iframe';
export const SPLITIONAME_CUSTOMER_ZIP = 'customer_zip_entry';
export const SPLITIONAME_GOOGLE_ADS_TV_MOUNT_SKU = 'google_ads_tv_mount_sku';
export const SPLITIONAME_PRODUCT_SHOP_VERSION_TEST = 'product_shop_version_test';
export const SPLITIONAME_IFRAME_LANDING_PAGE_GEEKSQUADHT = 'iframe_landing_page_geeksquadht';
export const SPLITIONAME_IFRAME_LANDING_PAGE_INSTALLSETUP = 'iframe_landing_page_installsetup';
export const SPLITIONAME_COMCAST_SELF_PROTECT_SKUS = 'comcast_self_protect_skus';
export const SPLITIONAME_ADD_SKU_CONFIRM_AND_RECOMMENDATIONS =
  'add_sku_confirm_and_recommendations';
export const SPLITNAME_REVIEW_VS_CONTINUE_TEXT_TREATMENT = 'review_vs_continue_text_treatment';
export const SPLITIONAME_ADDSKUPAGE_V2 = 'addskupage_v2';
export const SPLITNAME_PRODUCT_PAGE_PROMO_IMAGE = 'product_page_promo_image';
export const SPLITNAME_PREVENT_CANCELLATION_CS_NUMBER = 'prevent_cancellation_cs_number';
export const SPLITNAME_LP_INSTALL_SETUP_SHOW_SITE_NAV = 'lp_install_setup_show_site_nav';
export const SPLITNAME_TOS_AUGUST_2022_FEATURE_FLAG = 'tos_august_2022_feature_flag';
export const SPLITNAME_DYNAMIC_HOMEPAGE_BANNER = 'dynamic_homepage_banner';
export const SPLITNAME_UPSELL_OPT_IN_CART_SUMMARY = 'upsell_opt_in_cart_summary';
export const SPLITNAME_EV_EMPORIA_LANDING_PAGE = 'ev_emporia_landing_page';
export const SPLITIONAME_USER_EMAIL_VALIDATOR = 'user_email_validator';
export const SPLITIONAME_PAYMENT_SCREEN_PREAUTH_VARIANT = 'payment_screen_preauth_variant';
export const SPLITIONAME_HTH_UPSELL_MODAL_ORDER_SUMMARY_PAGE =
  'hth_upsell_modal_order_summary_page';
export const SPLITIONAME_PASSWORDLESS_CHECKOUT = 'passwordless_checkout';

/*
  STEP 2:
  An array that holds our valid splits.
  If we have a timeout or catastrophic error lets get our splits and set control treatments
*/
export const SPLTIONAMES_CONTROL_DEFAULT = [
  SPLITIONAME_PROMO_BAR,
  SPLITIONAME_NAV_PHONE_NUMBER,
  SPLITIONAME_INTERCOM_CHAT_MODULE,
  SPLITIONAME_MEMBER_BENEFITS_BUTTON_CHAT_MODULE,
  SPLITIONAME_POPULAR_ONLINE_SERVICES,
  SPLITIONAME_BANNER_RADIO_OFFER,
  SPLITIONAME_HOMEPAGE_SEARCH,
  SPLITIONAME_PLAN_PRICING,
  SPLITIONAME_LOW_TIER_MEMBERSHIP_V1,
  SPLITIONAME_PRODUCT_SPECIALIST_CHAT,
  SPLITIONAME_CART_PRODUCT_INSTALLATION_UPSELL,
  SPLITIONAME_WALMART_PLAN_UPSELL,
  SPLITIONAME_ORDER_CONFIRMATION_PAGE_LANGUAGE,
  SPLITIONAME_MYACCOUNT_CANCEL_ORDER_CALL_MODAL,
  SPLITIONAME_ORDER_CONFIRMATION_PAGE_UPSELL,
  SPLITIONAME_LP_MEMBERSHIP_TEST,
  SPLITIONAME_PRODUCT_PAGE_MEMBERSHIP_UPSELL,
  SPLITIONAME_HOMEPAGE_IFRAME,
  SPLITIONAME_CUSTOMER_ZIP,
  SPLITIONAME_PRODUCT_SHOP_VERSION_TEST,
  SPLITIONAME_IFRAME_LANDING_PAGE_GEEKSQUADHT,
  SPLITIONAME_IFRAME_LANDING_PAGE_INSTALLSETUP,
  SPLITIONAME_COMCAST_SELF_PROTECT_SKUS,
  SPLITNAME_REVIEW_VS_CONTINUE_TEXT_TREATMENT,
  SPLITIONAME_ADDSKUPAGE_V2,
  SPLITIONAME_ADD_SKU_CONFIRM_AND_RECOMMENDATIONS,
  SPLITNAME_PRODUCT_PAGE_PROMO_IMAGE,
  SPLITNAME_PREVENT_CANCELLATION_CS_NUMBER,
  SPLITNAME_LP_INSTALL_SETUP_SHOW_SITE_NAV,
  SPLITNAME_TOS_AUGUST_2022_FEATURE_FLAG,
  SPLITNAME_DYNAMIC_HOMEPAGE_BANNER,
  SPLITNAME_UPSELL_OPT_IN_CART_SUMMARY,
  SPLITNAME_EV_EMPORIA_LANDING_PAGE,
  SPLITIONAME_USER_EMAIL_VALIDATOR,
  SPLITIONAME_PAYMENT_SCREEN_PREAUTH_VARIANT,
  SPLITIONAME_HTH_UPSELL_MODAL_ORDER_SUMMARY_PAGE,
  SPLITIONAME_PASSWORDLESS_CHECKOUT,
];

/*
  RE: SPLITIO_SPLITS_WITH_ATTRIBUTES
  This is the default config settings if you need to have them outside of the props flow.
  Only needed for those splits who need config defaults.
  Note: We can use this object for overrides as needed.

  Doing it here is not required, it is just a convenience. We just need to make sure we remove unneeded items.

  USE: The useSplitIOTreatment will pick this up if the config you are expecting from split doesn't exist or it
       times out. You can always ignore it here, and put your default in the file of choice and just do a spread
       or Object.assign etc...
*/
// STEP 3
export const SPLITIO_SPLITS_WITH_ATTRIBUTES = Object.freeze({
  [SPLITIONAME_PROMO_BAR]: {
    name: SPLITIONAME_PROMO_BAR,
    config: { ...PROMO_BAR_CONFIG_DEFAULTS },
  },
  [SPLITIONAME_NAV_PHONE_NUMBER]: {
    name: SPLITIONAME_NAV_PHONE_NUMBER,
    config: { ...get(HT_INFO, 'phone.customers.alternativeOne', {}) },
  },
  [SPLITIONAME_BANNER_RADIO_OFFER]: {
    name: SPLITIONAME_BANNER_RADIO_OFFER,
    config: { ...BANNER_RADIO_OFFER_DEFAULTS },
  },
  [SPLITIONAME_WALMART_PLAN_UPSELL]: {
    name: SPLITIONAME_WALMART_PLAN_UPSELL,
    config: { ...UPSELL_PLAN_CONTENT_DEFAULTS },
  },
  [SPLITIONAME_WALMART_PLAN_UPSELL]: {
    name: SPLITIONAME_PRODUCT_SHOP_VERSION_TEST,
    config: { path: '' },
  },
});
