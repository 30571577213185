import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleHour } from 'src/components/AvailabilitySelector/actions';

/**
 * This hook is used to preseed the AvailabilitySelector component with the previously selected
 * availability. This is used when a user is in edit mode and has previously selected availability.
 *
 * @param {Object} config - The configuration object
 * @param {Immutable.Map} config.entity - An Immutable.js Map representing either an order or a cart, containing previously selected availability
 * @param {Immutable.List} config.availability - The current availability data as an Immutable.js List
 * @param {boolean} config.isEditMode - Whether the component is in edit mode
 * @param {Object} config.selectedDateTimesRef - A ref to store selected date times
 * @param {number} [config.spliceBy=0] - The number of items to splice when removing invalid groups
 * @returns {{seed: Array}} An object containing the seed data for pre-selected times
 */

export const useSchedulingEditModePreSeed = ({
  entity,
  availability,
  isEditMode,
  selectedDateTimesRef,
  spliceBy = 0,
}) => {
  const dispatch = useDispatch();
  const [seed, setSeedSelectedTimes] = useState([]);
  const [dirtyEdit, setDirtyEdit] = useState(false);

  const availabilityByDateTime = useSelector((state) =>
    state.getIn(['entities', 'availabilityByDateTime']),
  );

  /**
   * Matches a given Zulu time datestring to its corresponding date and hour in the availability data.
   *
   * @param {string} dateTime - A Zulu time datestring (e.g., "2024-07-02T16:00:00.000Z")
   * @returns {{date: string|null, hour: number|null}} An object containing the matched date and hour, or null if no match is found
   */
  const matchDateAndHour = (dateTime) => {
    const match = availabilityByDateTime[dateTime];
    if (!match) {
      return { date: null, hour: null };
    }

    const { dateIndex, hourIndex } = match;
    const date = availability.getIn([dateIndex, 'date']);
    const hour = availability.getIn([dateIndex, 'hours', hourIndex, 'hour']);

    return { date, hour };
  };
  /*
     If we have a spliceBy, we need to take our first invalid (assuming the beginning of a group, ie EV), and remove that group.
     This is needed because, unlike a single entry, EV has a group of hours that are selected. If one of those hours is invalid,
      we need to remove the entire group.
  */
  const spliceInvalidGroup = (entityAvailabilityJS) => {
    const recurseSpliceBy = (avail) => {
      const firstInvalidIndex = avail.findIndex((dateTime) => {
        const { date, hour } = matchDateAndHour(dateTime);

        return !determineValidity(date, hour);
      });

      if (firstInvalidIndex !== -1) {
        const splicedArray = avail.slice();
        splicedArray.splice(firstInvalidIndex, spliceBy);
        return recurseSpliceBy(splicedArray);
      }

      return avail;
    };

    return recurseSpliceBy(entityAvailabilityJS);
  };

  /* Take what the user previously selected and run thru the current availability to derive 'valid' */
  const determineValidity = (date, militaryTime) => {
    // get matched date from availability
    const matchedDate = availability.find((a) => a.get('date') === date);
    // get Matched hour
    const matchedHour = matchedDate?.get('hours').find((h) => h.get('hour') === militaryTime);

    return Boolean(matchedHour?.get('valid'));
  };

  /* Single date intent. No spliceBy/grouped selections */
  const removeInvalidDates = (entityAvailabilityJS) => {
    return entityAvailabilityJS.filter((dateTime) => {
      const { date, hour } = matchDateAndHour(dateTime);

      return determineValidity(date, hour);
    });
  };

  /**
   * This function is called on a page load when user has availability saved in the entity.
   * At this point, we know its an edit mode else they wouldn't have saved availability in the entity.
   *
   * We will take the saved availability and format it to match the format of the availability we use
   * in the AvailabilitySelector component.
   *   1. Reformat
   *   2. Dispatch to Action
   *   3. Single Dispatch, not batch.
   */
  const formatAndSubmitSavedAvailability = (validEntityAvailability) => {
    /* Run thru the presaved dates to dispatch to action */
    validEntityAvailability.forEach((dateTime) => {
      /* Lets get our needed momentjs attributes */
      const { date, hour } = matchDateAndHour(dateTime);

      // dispatch the valid preseed selection
      dispatch(
        toggleHour({
          date,
          selectedHourData: {
            dateTime,
            hour,
            valid: 1,
            checked: false,
          },
          newCheckState: true,
        }),
      );
    });
  };

  /* If we have no currently selected dates, but our entity has availability */
  useEffect(() => {
    // We have our entity and availability
    if (entity?.get('id') && availability?.size > 0 && isEditMode) {
      // What the user previously selected
      const entityAvailability = entity?.get('availability');

      // dirtyEdit is needed for when in edit mode, a user can deselect all their times
      if (seed.length === 0 && !dirtyEdit && entityAvailability.size > 0) {
        const valuesEntityAvailability = entityAvailability?.map((a) => a.get('value'));
        const entityAvailabilityJS = valuesEntityAvailability?.toJS() || [];
        /* Remove invalid dates from seeding */
        const validEntityAvailability = spliceBy
          ? spliceInvalidGroup(entityAvailabilityJS)
          : removeInvalidDates(entityAvailabilityJS);

        /* Format and submit the saved availability */
        formatAndSubmitSavedAvailability(validEntityAvailability);

        /* Align our seed value to the preseeding (what user prev selected) */
        setSeedSelectedTimes(validEntityAvailability);
        setDirtyEdit(true);
        // eslint-disable-next-line no-param-reassign
        selectedDateTimesRef.current = validEntityAvailability;
      }
    }
  }, [entity, availability, seed]);

  return { seed };
};
