import { getCartProperties } from 'src/containers/CartPage/actions';
import {
  SUBMIT_AVAILABILITY,
  AVAILABILITY_SUBMITTED,
  TOGGLE_MODAL,
  GET_AVAILABLE_TIMES,
  SUBMIT_TIMES_AND_UPDATE_CART,
} from './constants';

export function submitAvailability({ selectedDateTimes, skipMinCount = false }) {
  return {
    type: SUBMIT_AVAILABILITY,
    selectedDateTimes,
    skipMinCount,
  };
}

export function availabilitySubmitted({ id, updated = false, cart = {} }) {
  return {
    type: AVAILABILITY_SUBMITTED,
    updated,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Checkout Step Completed',
            properties: {
              step: 'Scheduling',
              cart_id: id,
              cart: getCartProperties(cart),
            },
          },
        },
      ],
    },
  };
}

export function toggleModal({ modalName }) {
  return {
    type: TOGGLE_MODAL,
    modalName,
  };
}

export function getAvailableTimes(payload = {}) {
  return {
    type: GET_AVAILABLE_TIMES,
    payload,
  };
}

export function submitTimesAndUpdateCart(payload) {
  return {
    type: SUBMIT_TIMES_AND_UPDATE_CART,
    payload,
  };
}
