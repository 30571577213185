import { addErrorPageSnackNotice } from 'src/actions/snackNotice';
import { TEMPLATE_NAMES } from 'src/components/Snackbar/constants';
import {
  ACCEPT_STAND_BY_TIME,
  CANCEL_ORDER,
  DECLINE_STAND_BY_TIMES,
  PAGE_NAME,
  SNACKBAR_NAME,
  CANCE_ORDER_SEGMENT_EVENT,
  MEMBERSHIP_ADDITION_TOAST_DISPLAYED,
} from './constants';

export const acceptStandByTime = ({ availabilityId, orderId, orderToken }) => ({
  type: ACCEPT_STAND_BY_TIME,
  orderId,
  orderToken,
  availabilityId,
});

export function cancelOrder({ orderId, orderToken, cancelReasonId, otherReason }) {
  return {
    type: CANCEL_ORDER,
    payload: { orderId, orderToken, cancelReasonId, otherReason },
  };
}

export const declineStandByTimes = ({ orderId, orderToken }) => ({
  type: DECLINE_STAND_BY_TIMES,
  orderId,
  orderToken,
});

export const authFailedErrorPageSnackNotice = ({ isLoggedIn, hasSetPassword }) => {
  return addErrorPageSnackNotice({
    pageName: PAGE_NAME,
    template: TEMPLATE_NAMES.AUTH_FAILED,
    templateData: { isLoggedIn, hasSetPassword },
    componentName: SNACKBAR_NAME,
    dismissable: false,
  });
};

export const initiateCancelOrderSegmentEvent = ({ order }) => {
  return {
    type: CANCE_ORDER_SEGMENT_EVENT,
    meta: {
      analytics: [
        {
          eventType: 'track',
          eventPayload: {
            event: 'Customer canceled',
            properties: {
              ...order,
            },
          },
        },
      ],
    },
  };
};

export const membershipAdditionToastDisplayed = (displayed) => {
  return {
    type: MEMBERSHIP_ADDITION_TOAST_DISPLAYED,
    payload: displayed,
  };
};
