import { DEFAULT_LAYOUT_NO_SITE_LINKS } from 'src/constants/common';

export const PAGE_NAME = 'reschedule';
export const SUBMIT_SCHEDULE = 'reschedulingPage/SUBMIT_SCHEDULE';
export const SCHEDULE_SUBMITTED = 'reschedulingPage/SCHEDULE_SUBMITTED';

export const RESCHEDULING_LAYOUT = Object.freeze({
  ...DEFAULT_LAYOUT_NO_SITE_LINKS,
  buttonFooter: { show: true, tabletShow: true, desktopShow: false },
  footer: { show: false },
});
export const PUBLIC_RESCHEDULING_LAYOUT = Object.freeze({
  ...RESCHEDULING_LAYOUT,
  header: { template: 'PlainHeader' },
});
