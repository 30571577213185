import React, { forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { CALLOUTBOX_THEMES } from './constants';
import styles from './styles.scss';

const sharedPropTypes = { children: PropTypes.node, className: PropTypes.string };

const DefaultTextContentContainer = ({ children }) => (
  <div className={cn(styles.textContent, 'paddingY-small')}>{children}</div>
);

const CalloutBox = forwardRef((props, ref) => {
  const {
    children,
    className,
    theme,
    header,
    text,
    textContentContainer: TextContentContainer,
  } = props;
  if (!text) return null;

  const themeStyles = {
    [styles.primary]: theme === CALLOUTBOX_THEMES.PRIMARY,
    [styles.critical]: theme === CALLOUTBOX_THEMES.CRITICAL,
    [styles.success]: theme === CALLOUTBOX_THEMES.SUCCESS,
  };

  const containerStyles = cn(styles.container, className, themeStyles);
  const highlightBarStyles = cn(styles.highlightBar, themeStyles);
  const headerStyles = cn(styles.header, 'marginBottom-tiny1', themeStyles);
  const textStyles = cn(styles.text, 'p2', themeStyles);
  const textContainerStyles = cn(styles.textContainer, 'paddingX-small');

  return (
    <section className={containerStyles} ref={ref}>
      <div className={highlightBarStyles} />
      <div className={textContainerStyles}>
        <TextContentContainer>
          {header && <h6 className={headerStyles}>{header}</h6>}
          <p className={textStyles}>{text}</p>
        </TextContentContainer>
        {children && <div className="paddingBottom-small">{children}</div>}
      </div>
    </section>
  );
});

CalloutBox.displayName = 'CalloutBox';

CalloutBox.propTypes = {
  ...sharedPropTypes,
  theme: PropTypes.string,
  header: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  textContentContainer: PropTypes.element,
};

CalloutBox.defaultProps = {
  theme: CALLOUTBOX_THEMES.DEFAULT,
  textContentContainer: DefaultTextContentContainer,
};

DefaultTextContentContainer.propTypes = { ...sharedPropTypes };

export default CalloutBox;
