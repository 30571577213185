import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { push, accountOrderPath, accountOrderPublicPath } from 'src/utils/paths';
import { LOAD_PAGE } from 'src/constants/common';
import { NEED_AT_LEAST_ONE_VALID_DATE } from 'src/constants/cart';
import { selectRoutes } from 'src/apiRoutes';
import { orderInfoLoaded } from 'src/actions/order';
import { newNotice } from 'src/components/NoticeStack/actions';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import {
  displayErrors,
  requestStarted,
  requestFinished,
  displayErrorsWithSnack,
} from 'src/utils/request';
import { ordersListStateSelector } from 'src/selectors/order';
import { pageLoaded, pageLoadingError, setZipCodeRestriction } from 'src/actions/common';
import { availabilityLoaded } from 'src/components/AvailabilitySelector/actions';
import { PAGE_NAME as RESCHEDULING_PAGE, SUBMIT_SCHEDULE } from './constants';
import { isTimeWindowAbTestOn } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';

function* pageSaga({ orderId, orderToken, publicPage }) {
  if (!publicPage) {
    const userLoaded = yield loadUserProfileSaga(RESCHEDULING_PAGE, true);
    if (!userLoaded) return;
  }

  const routes = yield call(selectRoutes);
  const response = yield call(routes.orders.schedule, { orderId, orderToken });
  if (response.err) {
    yield put(displayErrors(response));
  } else {
    const orderList = yield select(ordersListStateSelector);
    let orderInfo;
    if (publicPage) {
      orderInfo = orderList.find((order) => order.get('token') === orderToken);
    } else {
      orderInfo = orderList.find((order) => order.get('id') === orderId);
    }

    if (!orderInfo || !orderInfo.get('totalAmount')) {
      let orderResponse;
      if (publicPage) {
        orderResponse = yield call(routes.orders.public.info, { orderToken });
      } else {
        orderResponse = yield call(routes.orders.info, { orderId }, { full: true });
      }
      const {
        data: { order },
        err,
      } = orderResponse;
      if (err) {
        yield put(pageLoadingError(RESCHEDULING_PAGE, orderResponse));
        yield put(displayErrorsWithSnack(orderResponse));
        return;
      }
      orderInfo = order;
    } else {
      orderInfo = orderInfo.toJS();
    }

    yield put(orderInfoLoaded({ order: orderInfo }));
    const availability = response.data;
    yield put(availabilityLoaded({ availability }));

    const isOrderableZipResponse = yield call(routes.cart.checkZipCodeRestriction, {
      zip: orderInfo.clientZip,
    });
    if (!isOrderableZipResponse.err) {
      const {
        data: {
          data: { isZipRestricted, orderableFromDate },
        },
      } = isOrderableZipResponse;
      yield put(
        setZipCodeRestriction({
          isZipRestricted,
          orderableFromDate,
          page: RESCHEDULING_PAGE,
        }),
      );
    }

    yield put(pageLoaded(RESCHEDULING_PAGE));
  }
}

function* submitSaga({ ranges, orderId, orderToken, publicPage }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);
  let response;
  if (publicPage) {
    response = yield call(routes.orders.public.reschedule, { orderToken }, { range_data: ranges });
  } else {
    response = yield call(routes.orders.reschedule, { orderId }, { range_data: ranges });
  }
  yield put(requestFinished());

  const {
    data: { order },
    err,
  } = response;

  if (!err) {
    const useTimeWindows = isTimeWindowAbTestOn(order);
    if (!useTimeWindows) {
      yield put(
        newNotice({
          title: "We've updated your preferred service time.",
          content:
            "Please be aware that your appointment has not yet been confirmed. Once we have located just the right tech for your service, you'll receive a confirmation email with your confirmed appointment time and details about your tech",
        }),
      );
    }

    yield put(orderInfoLoaded({ order }));

    if (publicPage) {
      yield put(push(accountOrderPublicPath(orderToken)));
    } else {
      yield put(push(accountOrderPath(orderId)));
    }
  } else {
    const errors = response.data.errors || [];
    const errMsg = errors[0];
    if (errMsg === NEED_AT_LEAST_ONE_VALID_DATE) {
      yield put(
        newNotice({
          title: 'Your Selected Times Are No Longer Available',
          content: 'Please choose additional times.',
        }),
      );
    } else {
      yield put(displayErrors(response));
    }
  }
}

function* pageFlow() {
  yield takeLatest(
    (action) => action.type === LOAD_PAGE && action.page === RESCHEDULING_PAGE,
    pageSaga,
  );
  yield takeEvery(SUBMIT_SCHEDULE, submitSaga);
}

export default [pageFlow];
