import { useState } from 'react';

const useModalToggle = () => {
  const [isVisible, setIsVisible] = useState(false);

  function toggle(event) {
    event.preventDefault();
    setIsVisible(!isVisible);
  }

  return {
    isVisible,
    toggle,
  };
};

export default useModalToggle;
