export const ANIMATION_DURATION = {
  SLIDE: 200,
};

export const CALLOUTBOX_THEMES = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  CRITICAL: 'critical',
  SUCCESS: 'success',
};
