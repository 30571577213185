// Libraries
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import cn from 'classnames';
// Hooks && Utils
import { useSchedulingEditModePreSeed } from 'src/containers/BookingPage/AvailabilityPage/hooks';
import {
  isTimeWindowAbTestOn,
  normalizeAvailabilityForWindowBasedView,
} from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';
// Actions & Selectors
import { inEditModeSelector } from 'src/selectors/router';
// Data
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES } from 'src/components/AvailabilitySelectorWithConfig/Scheduling.constants';
import { AVAILABILITY_FORM_ID } from './constants';
// Components
import AvailabilitySelector from 'src/components/AvailabilitySelector';
import AvailabilitySelectorWithConfig from 'src/components/AvailabilitySelectorWithConfig';
import AvailabilityManager from 'src/components/AvailabilitySelector/AvailabilityManager';
import { AvailabilityManagerWithConfig } from 'src/components/AvailabilitySelectorWithConfig/AvailabilityManagerWithConfig';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import { Grid } from 'src/components/HTKit/Grid';
import { InvalidDatesModal, RecommendedDatesModal } from './Modals';
import AlternateTimesPrompt from './AlternateTimesPrompt';
// Styles, etc.
import styles from './styles.scss';

const TimeSelector = ({ useTimeWindows, selectedDateTimes, availability }) => {
  if (useTimeWindows) {
    return (
      <AvailabilitySelectorWithConfig
        availability={availability}
        selectedDateTimes={selectedDateTimes}
        config={TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES}
        enableDateToggle={false}
      />
    );
  }
  return <AvailabilitySelector availability={availability} selectedDateTimes={selectedDateTimes} />;
};

const TimeManager = ({ useTimeWindows, numSelectedHours, selectedHours, selectedDateTimes }) => {
  if (useTimeWindows) {
    return (
      <AvailabilityManagerWithConfig.TimeRangeDisplay
        numSelectedHours={numSelectedHours}
        selectedHours={selectedHours}
        selectedDateTimes={selectedDateTimes}
        config={TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES}
      />
    );
  }
  return (
    <AvailabilityManager
      numSelectedHours={numSelectedHours}
      selectedHours={selectedHours}
      selectedDateTimes={selectedDateTimes}
    />
  );
};

const HeaderBlock = ({ useTimeWindows, partner, previouslySelectedTimes }) => {
  if (useTimeWindows) {
    return (
      <>
        <h3 className="text-align-center marginTop-tiny1">When should we arrive?</h3>
        <p className={cn(styles.subHeader, 'p2 n700')}>
          Your tech will provide an exact ETA when en route
        </p>
      </>
    );
  }
  return (
    <>
      <h3 className={styles.header}>When are you available?</h3>
      <AlternateTimesPrompt partner={partner} previouslySelectedTimes={previouslySelectedTimes} />
    </>
  );
};

const SubmitButton = (props) => (
  <Button theme={THEMES.V2PRIMARY} {...props}>
    Continue
  </Button>
);
const AvailabilityPage = ({
  loadPage,
  submitAvailability,
  toggleModal,
  cart,
  page,
  currentStage,
  availability,
  numSelectedHours,
  selectedHours,
  partner,
}) => {
  const isEditMode = useSelector(inEditModeSelector);
  const useTimeWindows = isTimeWindowAbTestOn(cart);

  const selectedDateTimesRef = useRef([]);
  const { seed } = useSchedulingEditModePreSeed({
    selectedDateTimesRef,
    availability,
    isEditMode,
    entity: cart,
  });
  const selectedDateTimes = useMemo(() => {
    return selectedDateTimesRef.current;
  }, [selectedDateTimesRef, seed.length]);

  useEffect(() => {
    if (currentStage === BOOKING_STAGES.AVAILABILITY) {
      loadPage(BOOKING_STAGES.AVAILABILITY);
    }
  }, []);

  const onSubmitAvailability = (event) => {
    event.preventDefault();
    submitAvailability({
      selectedDateTimes,
      skipMinCount: false,
    });
  };

  const submitAvailabilitySkipMin = (event) => {
    if (event) event.preventDefault();
    submitAvailability({
      selectedDateTimes,
      skipMinCount: true,
    });
  };

  const handleToggleModal = (modalName) => {
    return () => {
      toggleModal({ modalName });
    };
  };

  if (currentStage !== BOOKING_STAGES.AVAILABILITY || page.get('loading')) return null;
  const previouslySelectedTimes = cart && cart.get('availability');
  const disableSubmitButton = selectedDateTimes.length === 0;
  const availabilityChompedChunked = normalizeAvailabilityForWindowBasedView({
    availability,
    config: TIME_WINDOWS_BASE_SCHEDULING_ATTRIBUTES,
  });
  const showSubmitButtonBlock = !useTimeWindows || (useTimeWindows && !disableSubmitButton);

  return (
    <Grid.Fluid classes="site-v2">
      <Grid.Row>
        <Grid.Column sm={4} md={8} lgOffset={2} lg={8}>
          <Helmet title="Availability" />
          <div className={styles.sectionHeaderWrapper}>
            <HeaderBlock
              useTimeWindows={useTimeWindows}
              partner={partner}
              previouslySelectedTimes={previouslySelectedTimes}
            />
          </div>
          <div className="form" id={AVAILABILITY_FORM_ID}>
            <TimeSelector
              useTimeWindows={useTimeWindows}
              selectedDateTimes={selectedDateTimes}
              availability={useTimeWindows ? availabilityChompedChunked : availability}
            />
            <div className="showTabletDesktopV2">
              <TimeManager
                useTimeWindows={useTimeWindows}
                numSelectedHours={numSelectedHours}
                selectedHours={selectedHours}
                selectedDateTimes={selectedDateTimes}
              />
            </div>
            {showSubmitButtonBlock && (
              <div className={cn(styles.buttonWrapper, styles.showTabletDesktop)}>
                <SubmitButton disabled={disableSubmitButton} onClick={onSubmitAvailability} />
              </div>
            )}
          </div>
          {showSubmitButtonBlock && (
            <div className={cn(styles.buttonWrapperMobile, 'showMobileOnlyV2')}>
              <TimeManager
                useTimeWindows={useTimeWindows}
                numSelectedHours={numSelectedHours}
                selectedHours={selectedHours}
                selectedDateTimes={selectedDateTimes}
              />
              <SubmitButton disabled={disableSubmitButton} onClick={onSubmitAvailability} />
            </div>
          )}
          <InvalidDatesModal
            visible={page.getIn(['modals', 'invalidDates'])}
            onCancel={handleToggleModal('invalidDates')}
          />
          <RecommendedDatesModal
            visible={page.getIn(['modals', 'recommendedDates'])}
            onCancel={handleToggleModal('recommendedDates')}
            submit={submitAvailabilitySkipMin}
            selectedDateTimes={selectedDateTimes}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid.Fluid>
  );
};
AvailabilityPage.propTypes = {
  submitAvailability: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  availability: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  partner: PropTypes.string,
  currentStage: PropTypes.string,
  isMobile: PropTypes.bool,
  selectedHours: PropTypes.array.isRequired,
  numSelectedHours: PropTypes.number.isRequired,
  addAppSnackNotice: PropTypes.func,
  clearAppNotices: PropTypes.func,
  snackbar: PropTypes.object,
};
const timeWindowPropType = {
  useTimeWindows: PropTypes.bool,
};
TimeSelector.propTypes = {
  ...timeWindowPropType,
  selectedDateTimes: PropTypes.array.isRequired,
  availability: PropTypes.object.isRequired,
};
TimeManager.propTypes = {
  ...timeWindowPropType,
  selectedDateTimes: PropTypes.array.isRequired,
  selectedHours: PropTypes.array.isRequired,
  numSelectedHours: PropTypes.number.isRequired,
};
HeaderBlock.propTypes = {
  ...timeWindowPropType,
  partner: PropTypes.string,
  previouslySelectedTimes: PropTypes.array,
};
export default AvailabilityPage;
