/* eslint-disable camelcase */
import moment from 'moment';
import { call, select } from 'redux-saga/effects';
import { selectRoutes } from 'src/apiRoutes';
import { extractZipFromAddress } from 'src/utils/address';
import { PST_TIMEZONE_LA } from 'src/constants/app';

const getZip = (cart) => {
  if (!cart.address) return '';
  if (cart.address.zip) return cart.address.zip;
  return extractZipFromAddress(cart.address.fullAddress).zip;
};

/**
 * Fetches the available times for booking an appointment from the BE.
 *
 * @param {Object} params - The parameters for the API call.
 * @param {number} params.numHoursToBuffer - Number of hours to add to the first available appointment time.
 * @param {number} params.numDaysToBuffer - Number of days to add to the first available appointment time.
 *
 * @returns {Object} The response object containing the available times.
 */
export function* getAvailableTimesApi({ numHoursToBuffer = 0, numDaysToBuffer= 0 }) {
  const routes = yield call(selectRoutes);
  const cart = yield select((state) => state.getIn(['entities', 'cart']));

  const lat = cart.get('lat');
  const lng = cart.get('lng');
  const items = cart.get('items');
  const tz = cart.get('timezone');
  const isRemote = cart.get('remote');
  const hasProducts = cart.get('hasProducts');
  const orderId = cart.getIn(['order', 'id'], null);

  const query = {
    source: 'client',
    tz: tz || PST_TIMEZONE_LA,
    zip: getZip(cart.toJS()),
  };

  if (hasProducts) {
    query.isProduct = true;
  }

  if (items.size > 0) {
    query.skus = [];
    items.forEach((item) => query.skus.push(item.get('skuId')));
  }

  if (isRemote) {
    query.isRemote = true;
  } else {
    if (lng) query.lng = lng;
    if (lat) query.lat = lat;
  }

  if (orderId) {
    query.orderId = orderId;
  }

  if (numHoursToBuffer) {
    query.numHoursToBuffer = numHoursToBuffer;
  }

  if (numDaysToBuffer) {
    query.numDaysToBuffer = numDaysToBuffer;
  }

  const response = yield call(routes.cart.availableTimes, query);
  return response;
}

export function* checkZipCodeRestriction() {
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  const routes = yield call(selectRoutes);
  const zip = getZip(cart.toJS());
  const response = yield call(routes.cart.checkZipCodeRestriction, { zip });
  return response.err ? response.err : response.data;
}

export const isTodaysDate = (date) => moment(date).isSame(moment(), 'day');

export const formatDate = (date, format) => {
  return moment(date, 'YYYY-MM-DD').format(format);
};

export const formatHour = (hour) => {
  let formatted = hour;
  if (hour === 12) {
    formatted = '12pm';
  } else if (hour < 12) {
    formatted = `${hour}am`;
  } else if (hour > 12) {
    formatted = `${hour - 12}pm`;
  }
  return formatted;
};

export const dateHasSelectedHours = (date) => {
  const selectedHours = date.hours.find((elem) => elem.checked);
  return Boolean(selectedHours);
};

export const updateSelectedDateTimes = (selectedDateTimes, selectedHourData, newCheckState) => {
  const index = selectedDateTimes.indexOf(selectedHourData.get('dateTime'));
  if (index === -1 && newCheckState) {
    selectedDateTimes.push(selectedHourData.get('dateTime'));
  } else if (index !== -1 && !newCheckState) {
    selectedDateTimes.splice(index, 1);
  }
};
