import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { deriveNameByDateRange } from './Scheduling.utils';
import styles from './styles.scss';

const MobileHourBlock = ({ date, hourData, toggleHour }) => {
  const hourStyles = classNames('p2', styles.block, {
    /* If any hours are invalid, invalidate the whole window */
    [styles.invalid]: hourData.some((hour) => !hour.valid),
    [styles.selected]: hourData.every((hour) => hour.checked),
  });

  /* We take the group, and iterate over for each hour and toggle */
  const submitByEachHour = (event) => {
    const checked = hourData.some((hour) => hour.checked);

    toggleHour(date, hourData, !checked)(event);
  };

  return (
    <div role="button" className={hourStyles} onClick={submitByEachHour}>
      {deriveNameByDateRange(hourData)}
    </div>
  );
};

MobileHourBlock.propTypes = {
  date: PropTypes.string.isRequired,
  hourData: PropTypes.object.isRequired,
  toggleHour: PropTypes.func.isRequired,
};

export default MobileHourBlock;
