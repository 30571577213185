import tmz from 'moment-timezone';
import moment from 'moment';
import { logger } from 'src/utils/logger';

export const FORMAT = {
  DATE: 'MM/DD/YYYY',
  SCHEDULED: 'ddd l [at] h:mma',
  INVALID: 'Invalid Date',
  TIME: 'h:mma',
};

export const displayTimestamp = (timestamp, format = FORMAT.DATE) =>
  moment(timestamp).format(format);

/**
 * Scheduled Format
 * Will return the following display: Wed 12/18/2019 at 4:00pm"
 * @param date
 * @param options { timezone } - accepts a timezone
 * @returns {string}
 */
export const scheduledFormat = (date, { timezone } = {}) => {
  let formattedDate;

  try {
    formattedDate = timezone
      ? tmz.tz(date, timezone).format(FORMAT.SCHEDULED)
      : moment(date).format(FORMAT.SCHEDULED);
  } catch (e) {
    formattedDate = FORMAT.INVALID;
    logger('scheduledFormat')(e);
  }

  return formattedDate === FORMAT.INVALID ? '' : formattedDate;
};

export const THIRTY_MINS_IN_MS = 30 * 60 * 1000;
export const FIFTEEN_MINS_IN_MS = 15 * 60 * 1000;

/**
 * This deals with "dates/times". Convert minutes to hours, in 30 minute increments
 * @param duration
 * @param postPend - pluralizes if greater than 1
 * @returns {number}
 */
export const convertMinutesToHours = (duration, postPend = false) => {
  if (typeof parseInt(duration, 10) !== 'number') return '';

  // Convert minutes to hours
  const hours = duration / 60;

  // Round to the nearest half-hour increment
  const roundedHours = Math.round(hours * 2) / 2;

  // If the duration is greater than 1 and we want a postpend of hrs, pluralize the postPend. add parens for readability
  const result = roundedHours + (postPend ? `hr${roundedHours > 1 ? 's' : ''}` : '');

  return result;
};
