import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import Icon from 'src/components/HTKit/Icon';
import { SIZES } from './constants';
import styles from './styles.scss';

const ModalV2 = ({
  isVisible,
  size,
  hide,
  subHeader,
  header,
  headerContainerClasses = '',
  headerClasses = '',
  children,
  childrenClassName,
  modalClassName,
  footerClasses,
  footerElement1,
  footerElement2,
  footerElement3,
  footerElement1Classes,
  footerElement2Classes,
  footerElement3Classes,
}) => {
  const closeStyles = classNames('plainButton', styles.modalClose);
  const headerContainerStyles = classNames(styles.headerContainer, headerContainerClasses);
  const headerStyles = classNames('h3', styles.header, headerClasses);
  const subHeaderStyles = classNames('p1 n700', styles.subHeader);
  const footerElement1Styles = classNames(footerElement1Classes, styles.footerElement1);
  const footerElement2Styles = classNames(footerElement2Classes, styles.footerElement2);
  const footerElement3Styles = classNames(footerElement3Classes, styles.footerElement3);
  const slideAnimation = isVisible ? styles.slideUp : styles.slideDown;
  const footerStyles = classNames(footerClasses, styles.footerContainer);
  const className = classNames(
    styles.commonModalStyles,
    slideAnimation,
    {
      [styles.modalLarge]: size === SIZES.LARGE,
      [styles.modalMedium]: size === SIZES.MEDIUM,
      [styles.modalSmall]: size === SIZES.SMALL,
    },
    modalClassName,
  );
  const childrenStyles = classNames(styles.modal, childrenClassName);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isVisible}
      className={className}
      overlayClassName={styles.overlay}
      contentLabel={header}
    >
      <div className={headerContainerStyles}>
        <h3 className={headerStyles}>{header}</h3>
        <button type="button" onClick={hide} className={closeStyles}>
          <Icon name="v2-close-icon" className={styles.closeIcon} />
        </button>
      </div>
      {subHeader && <p className={subHeaderStyles}>{subHeader}</p>}
      <div className={childrenStyles}>{children}</div>
      {(footerElement1 || footerElement2 || footerElement3) && (
        <div className={footerStyles}>
          <div className={footerElement1Styles}>{footerElement1}</div>
          <div className={styles.footerElementContainer}>
            {footerElement2 && <div className={footerElement2Styles}>{footerElement2}</div>}
            {footerElement3 && <div className={footerElement3Styles}>{footerElement3}</div>}
          </div>
        </div>
      )}
    </ReactModal>
  );
};

ModalV2.propTypes = {
  header: PropTypes.string,
  headerContainerClasses: PropTypes.string,
  headerClasses: PropTypes.string,
  subHeader: PropTypes.string,
  footerElement1: PropTypes.node,
  footerElement2: PropTypes.node,
  footerElement3: PropTypes.node,
  size: PropTypes.string,
  isVisible: PropTypes.bool,
  hide: PropTypes.func,
  children: PropTypes.node,
  childrenClassName: PropTypes.string,
  modalClassName: PropTypes.string,
  footerClasses: PropTypes.string,
  footerElement1Classes: PropTypes.string,
  footerElement2Classes: PropTypes.string,
  footerElement3Classes: PropTypes.string,
};

ModalV2.defaultProps = {
  size: SIZES.MEDIUM,
  isVisible: false,
  modalClassName: '',
};

export default React.memo(ModalV2);

/*
  ModalV2: Wrapper for modals

  Usage:
  import { ModalV2, useModalToggle, SIZES } from 'src/components/HTKit/Modals/ModalV2';

  If the footerItems are buttons:
  import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
  or link:
  import { Link } from 'react-router-dom';

  inside the parent functional component:
  const { isVisible, toggle } = useModalToggle();

  Typically in a modal, the minimum would be one button right-justified. In the event there are 2 buttons and a link, the buttons are right-justified and link is left-justified and this is the usage:
  const footerElement1 = <Link>Footer element - this is a link <Link> (This will be a left-justified Link(or a button, if necessary) when used with the other elements below)
  const footerElement2 = <Button>Footer element - this is a button <Button> (This will be one of right-justified the buttons)
  const footerElement3 = <Button>Footer element - this is a button <Button> (This will be one of right-justified the buttons)

  In a class component, add state and, a method to toggle open/close modal.

  <button onClick={toggle}>Your Show Modal Button</button>

  <ModalV2
    isVisible={isVisible}
    hide={toggle}
    header="Custom Header"
    subHeader="Custom SubHeader"
    footerElement1={footerElement1}
    footerElement2={footerElement2}
    footerElement3={footerElement3}
    size={SIZES.MEDIUM}
  >
    {children (any other component)}
  </ModalV2>
*/
