import { fromJS } from 'immutable';
import { PAGE_LOADED } from 'src/constants/common';
import { PAGE_NAME as ORDER_PAGE_NAME } from 'src/containers/Account/OrderPage/constants';

const cancelReasonsInitialState = fromJS({ list: [] });

export default function cancelReasonsReducer(state = cancelReasonsInitialState, action) {
  switch (action.type) {
    case PAGE_LOADED:
      switch (action.page) {
        case ORDER_PAGE_NAME:
          return state.merge({ list: action.cancelReasons });
        default:
          return state;
      }
    default:
      return state;
  }
}
