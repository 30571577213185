import React from 'react';
import PropTypes from 'prop-types';
import { StageEdit, StageEditHeader } from 'src/components/OrderSummary';
import OrderSummaryLineItem from 'src/components/OrderSummary/Breakdown/OrderSummaryLineItem';
import {
  isTimeWindowAbTestOn,
  getFormattedAvailabilityForTimeWindows,
} from 'src/components/AvailabilitySelectorWithConfig/Scheduling.utils';
import CartAvailabilityAccordion from './CartAvailabilityAccordion';

export const CartAvailabilityBlock = ({
  isVisible,
  cart,
  onHeaderClick,
  consolidatedAvailability,
  availability,
  selectedServiceTimes,
}) => {
  if (!isVisible) return null;

  const useTimeWindows = isTimeWindowAbTestOn(cart);
  const headerText = useTimeWindows ? 'Your Appointment' : 'Your Availability';

  let content;
  if (useTimeWindows && consolidatedAvailability) {
    const { formattedDate, formattedTimes } = getFormattedAvailabilityForTimeWindows(
      consolidatedAvailability,
    );
    content = (
      <>
        <p className="p1 n700">{formattedDate}</p>
        <p className="p1 n700">Tech arrival between {formattedTimes}</p>
      </>
    );
  } else if (consolidatedAvailability) {
    content = <CartAvailabilityAccordion times={selectedServiceTimes} />;
  } else {
    content = availability.map((a, i) => (
      <OrderSummaryLineItem key={i}>{a.get('display')}</OrderSummaryLineItem>
    ));
  }

  return (
    <StageEdit>
      <StageEditHeader headerText={headerText} onClick={onHeaderClick} />
      {content}
    </StageEdit>
  );
};

CartAvailabilityBlock.propTypes = {
  isVisible: PropTypes.bool,
  cart: PropTypes.object,
  onHeaderClick: PropTypes.func,
  consolidatedAvailability: PropTypes.object,
  availability: PropTypes.array,
  selectedServiceTimes: PropTypes.array,
};
