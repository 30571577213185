// Libraries
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hooks
import { useIsVirtualVisitRequired } from 'src/containers/BookingPage/hooks';
// Selectors
import { pureUserSelector } from 'src/selectors/user';
import {
  affirmPaymentSelectedSelector,
  atLeastOnePriceTypeHourlySelector,
  pureCartSelector,
  standardAndSubscriptionCouponSelector,
} from 'src/selectors/cart';
import { purePageSelector } from 'src/selectors/page';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { urlParamCouponSelector } from 'src/selectors/coupon';
import { addCoupon as addCouponAction } from 'src/containers/BookingPage/SummaryPage/actions';
// Actions
import { addUrlParamCoupon } from 'src/actions/common';
// Components/Utils/Constants
import { skipPartnerPayment } from 'src/sagas/pagesFlow';
import { BOOKING_STAGES } from 'src/containers/BookingPage/constants';
import { push, bookingStagePath } from 'src/utils/paths';
import { isPlanOnlyCart } from 'src/utils/cart';
import { COUPON_ADJUSTMENT_TYPES } from 'src/components/OrderSummary/Breakdown/constants';
import { SUMMARY_PAGE_NAME_SHORT } from './constants';

import {
  Address,
  CardData,
  StageEdit,
  StageEditHeader,
  CardDataAffirm,
  ContactInfo,
} from 'src/components/OrderSummary';
import { Grid } from 'src/components/HTKit/Grid';
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import LowTierMembershipUpsell from 'src/containers/CartPage/Parts/LowTierMembershipUpsell';
import OrderBreakdownLineItem from 'src/components/OrderSummary/Breakdown/OrderBreakdownLineItem';
import ServiceBreakdownItem from 'src/components/OrderSummary/Breakdown/ServiceBreakdownItem';
import CouponAppliedLineItem from 'src/components/OrderSummary/Breakdown/CouponAppliedLineItem';
import ServiceImage from 'src/components/OrderSummary/Breakdown/ServiceImage';
import CouponInputV2 from 'src/components/OrderSummary/Breakdown/CouponInputV2';
import OrderTotalV2 from 'src/components/OrderSummary/Breakdown/OrderTotalV2';
import OrderAdjustmentsBreakdown from 'src/components/OrderSummary/Breakdown/OrderAdjustmentsBreakdown';
import { AFFIRM_PROMO_TYPES, AffirmPromo, AffirmReviewNote } from 'src/components/Affirm';
import PreferredTechCard from 'src/components/Techs/PreferredTechCard';
import HourlyPriceDisclaimer from 'src/components/Cart/Disclaimers/HourlyPriceDisclaimer';
import { HTHUpsellModal } from 'src/containers/CartPage/Parts/HTHMembershipUpsell/HTHUpsellModal';
import { CartAvailabilityBlock } from './Parts/CartAvailabilityBlock';
import PlanWithProductsInCartLineItems from './Parts/PlanWithProductsInCartLineItems';
import PlanInRegularCartLineItems from './Parts/PlanInRegularCartLineItems';
import CancellationDisclaimer from './Parts/CancellationDisclaimer';
import VirtualServiceNotice from './VirtualServiceNotice';
import VirtualSiteVisitCalloutBox from './Parts/VirtualSiteVisitCalloutBox';

// Styles
import styles from './styles.scss';

// eslint-disable-next-line react/prop-types
const SubmitButton = ({ text = 'Complete Your Order', onClick }) => {
  const affirmPayment = useSelector(affirmPaymentSelectedSelector);

  return (
    <>
      {affirmPayment && <AffirmReviewNote buttonText={text} />}
      <Button onClick={onClick} theme={THEMES.V2PRIMARY}>
        {text}
      </Button>
      <CancellationDisclaimer className="marginTop-small" />
    </>
  );
};

const ServiceBreakdownItems = ({ services }) => {
  if (!services) return null;
  return (
    <div className="marginBottom-small">
      {services.map((service, i) => (
        <div key={`service-${i}`} className={cn('marginBottom-small2', styles.itemContainer)}>
          <ServiceImage
            image={service.image}
            quantity={service.quantity}
            showQty={service.quantity > 1}
          />
          <ServiceBreakdownItem {...service} />
        </div>
      ))}
    </div>
  );
};

const SummaryPageContent = (props) => {
  const {
    addCoupon,
    cart,
    confirmBooking,
    editStage,
    couponValueFormatted,
    promoChange,
    promoValue,
    removeCoupon,
    couponErrors,
    isRemoteCart,
    breakdownItems,
    orderAdjustmentsNoCoupon,
    plan,
    planName,
    planTotalSubText,
    serviceTotalFormatted,
    serviceTax,
    serviceTaxFormatted,
    subscriptionTaxFormatted,
    totalTaxFormatted,
    subscriptionTotal,
    coupon,
    couponText,
    card,
    selectedServiceTimes,
    hasProductsInCart,
    hasOnlyProductsInCart,
    priceBreakdown,
    consolidatedAvailability,
    availability,
    subscriptionCouponValue,
    planAmount,
    showPaymentSource,
    preferredTechData,
    badgeSavings = {},
    subscriptionCreditAdjustments,
  } = props;
  if (!cart) return null;

  // Plans where membership is controlled by a partner (Innover, etc)
  const requiresPartnerMembership = plan && plan.get('requiresPartnerMembership');

  // Hooks
  const standardAndSubscriptionCoupon = useSelector(standardAndSubscriptionCouponSelector);
  const isVirtualVisitRequired = useIsVirtualVisitRequired({ cart });
  const showHourlyDisclaimer = useSelector(atLeastOnePriceTypeHourlySelector);

  // Conditionals
  const showAvailability = !isPlanOnlyCart(cart) && !isRemoteCart && !hasOnlyProductsInCart;
  const showServiceTaxes = serviceTax > 0;
  const showTaxesWithProduct = showServiceTaxes && hasProductsInCart && !plan;
  const showTaxesWithoutProduct = showServiceTaxes && !hasProductsInCart;
  const hasSubscriptionCoupon =
    standardAndSubscriptionCoupon?.[COUPON_ADJUSTMENT_TYPES.SUBSCRIPTION];
  const shouldShowServiceCoupon = Boolean(
    standardAndSubscriptionCoupon?.[COUPON_ADJUSTMENT_TYPES.STANDARD],
  );
  const isOrderAdjustmentsNoCoupon = Boolean(
    orderAdjustmentsNoCoupon && orderAdjustmentsNoCoupon.length,
  );
  const showServiceFeesSection =
    showTaxesWithoutProduct ||
    shouldShowServiceCoupon ||
    isOrderAdjustmentsNoCoupon ||
    showTaxesWithProduct;
  const showCouponInput = !coupon && !requiresPartnerMembership;

  // Values derived from conditionals
  const orderSummaryHeader = hasProductsInCart ? 'Order Summary' : 'Service Summary';
  const orderTotalText = hasProductsInCart ? 'Total' : 'Due after Service';
  const totalPriceWithProduct = hasProductsInCart ? priceBreakdown : serviceTotalFormatted;
  const submitButtonText = (() => {
    if (requiresPartnerMembership) return 'Activate Your Benefits';
    return breakdownItems && breakdownItems.length >= 1
      ? 'Complete Your Order'
      : 'Complete Your Purchase';
  })();
  const subscriptionTaxes = !hasProductsInCart ? subscriptionTaxFormatted : totalTaxFormatted;

  // Style declarations
  const panelStyles = cn(styles.panel, 'padding-small2');
  const couponStyles = hasProductsInCart
    ? cn('marginBottom-tiny1 paddingTop-tiny1', styles.taxes)
    : 'marginBottom-small';
  const taxesStyles =
    hasProductsInCart && !coupon
      ? cn('marginBottom-small paddingTop-small', styles.taxes)
      : 'marginBottom-small';
  const serviceStyles = hasProductsInCart && !plan ? cn('paddingTop-small', styles.taxes) : '';
  const serviceFeesStyles = !hasProductsInCart ? cn(styles.taxes, 'paddingTop-tiny1') : null;
  const orderAdjustmentsWrapperStyles = cn({ 'marginBottom-medium': plan && hasProductsInCart });

  /* ---------- LOW TIER UPSELL SPECIFIC ITEMS */
  const { splitLoaded } = useSplitIOTreatment(splitioConstants.SPLITIONAME_LOW_TIER_MEMBERSHIP_V1);
  const canShowUpsellInNoPlanCart = splitLoaded && !plan;
  const dispatch = useDispatch();

  /* ---------- END LOW TIER UPSELL SPECIFIC ITEMS */

  /* -------- AFFIRM SPECIFIC ITEMS  */
  const userPure = useSelector(pureUserSelector);
  const cartPure = useSelector(pureCartSelector);
  const affirmPayment = useSelector(affirmPaymentSelectedSelector);

  /**
   * This calls the Affirm Checkout Modal to start their modal process
   * @constructor
   */
  const affirmCheckoutStart = () => {
    AffirmPromo.initializeCheckout({ userPure, cartPure, successCallback: confirmBooking });
  };
  /* -------- END AFFIRM SPECIFIC ITEMS  */
  const pageReduxState = useSelector(purePageSelector(SUMMARY_PAGE_NAME_SHORT)) || {};
  const { showHthModal } = pageReduxState.lowTierConfig;

  const userHasPayment = Boolean(userPure.account.card);
  const cartHasPayment = Boolean(cartPure.card);
  const urlParamCoupon = useSelector(urlParamCouponSelector);
  const handleSubmitOrder = () => {
    if (affirmPayment) {
      affirmCheckoutStart();
    } else if (!userHasPayment && !cartHasPayment) {
      if (skipPartnerPayment(cartPure)) {
        /*
          Ordinarily we want to capture the payment before proceeding, but
          if the payment screen was intentionally skipped we want to
          just proceed.
        */
        confirmBooking();
        return;
      }
      dispatch(
        push({
          pathname: bookingStagePath(BOOKING_STAGES.PAYMENT),
          search: `override=${BOOKING_STAGES.PAYMENT}`,
        }),
      );
    } else {
      confirmBooking();
    }
  };

  // Direct Booking flow
  const { techName, imageUrl } = preferredTechData;

  /*
   Lets deal with a param coupon. Also, lets use the same mechanism that is in place setParams.
   In every case the coupon needs to be removed. We just carried it here for the operation.
     1. valid coupon. - remove
     2. invalid - remove
     3. have previous coupon - remove
   */
  useEffect(() => {
    if (!couponText && urlParamCoupon) {
      dispatch(addCouponAction(urlParamCoupon));
      promoChange({ target: { value: urlParamCoupon } });
    }

    dispatch(addUrlParamCoupon(null));
  }, [couponText]);

  return (
    <>
      <h3 className="text-align-center">Review Your Order</h3>
      <Grid.Row classes={cn(styles.content)}>
        <Grid.Column sm={4} md={8} lgOffset={1} lg={4} classes="marginBottom-small">
          <PanelV2 className={panelStyles} lightShadow>
            <StageEdit>
              <StageEditHeader headerText="Contact" onClick={editStage('verification')} />
              <ContactInfo name={userPure.name} email={userPure.email} phone={userPure.phone} />
            </StageEdit>
            <StageEdit>
              <StageEditHeader headerText="Address" onClick={editStage('address')} />
              <Address address={cart.get('address').toJS()} showDetails />
            </StageEdit>
            <CartAvailabilityBlock
              isVisible={showAvailability}
              cart={cart}
              onHeaderClick={editStage('availability')}
              consolidatedAvailability={consolidatedAvailability}
              availability={availability}
              selectedServiceTimes={selectedServiceTimes}
            />
            {(showPaymentSource || affirmPayment) && (
              <StageEdit>
                <StageEditHeader headerText="Payment" onClick={editStage('payment')} />
                {affirmPayment ? (
                  <CardDataAffirm />
                ) : (
                  <CardData
                    brand={card.get('brand')}
                    digits={card.get('lastDigits')}
                    expiration={`${card.get('expMonth')}/${card.get('expYear')}`}
                  />
                )}
              </StageEdit>
            )}
          </PanelV2>

          <PreferredTechCard
            isRemote={isRemoteCart}
            cartHasOnlyProducts={hasOnlyProductsInCart}
            techName={techName}
            imageUrl={imageUrl}
            className="marginTop-small"
            lightShadow
          />

          {isRemoteCart && (
            <PanelV2 className={cn(panelStyles, 'marginTop-small')} lightShadow>
              <VirtualServiceNotice />
            </PanelV2>
          )}

          <PanelV2 className={cn(panelStyles, styles.buttonWrapper, 'marginTop-small')} lightShadow>
            <SubmitButton text={submitButtonText} onClick={handleSubmitOrder} />
          </PanelV2>
        </Grid.Column>

        <Grid.Column sm={4} md={8} lg={6} classes="marginBottom-small">
          {showCouponInput && (
            <PanelV2 className={cn(panelStyles, 'marginBottom-small')} lightShadow>
              <CouponInputV2
                value={promoValue}
                placeHolder="Enter a coupon code"
                couponErrors={couponErrors}
                onChange={promoChange}
                onClick={addCoupon}
              />
            </PanelV2>
          )}

          <PanelV2 className={panelStyles} lightShadow>
            {Boolean(breakdownItems && breakdownItems.length) && (
              <div>
                <h5 className={styles.orderSummaryHeader}>{orderSummaryHeader}</h5>
                <ServiceBreakdownItems services={breakdownItems} />

                {canShowUpsellInNoPlanCart && <LowTierMembershipUpsell />}

                {showServiceFeesSection && (
                  <div className={serviceFeesStyles}>
                    {showTaxesWithoutProduct && (
                      <OrderBreakdownLineItem
                        name="Taxes"
                        amount={serviceTaxFormatted}
                        className="marginBottom-small"
                      />
                    )}
                    {shouldShowServiceCoupon && (
                      <CouponAppliedLineItem
                        name={couponText}
                        amount={couponValueFormatted}
                        onClick={removeCoupon}
                        couponType={COUPON_ADJUSTMENT_TYPES.STANDARD}
                      />
                    )}
                    <div className={serviceStyles}>
                      {isOrderAdjustmentsNoCoupon && (
                        <div className={orderAdjustmentsWrapperStyles}>
                          <OrderAdjustmentsBreakdown adjustments={orderAdjustmentsNoCoupon} />
                        </div>
                      )}
                      {showTaxesWithProduct && (
                        <OrderBreakdownLineItem
                          name="Taxes"
                          amount={serviceTaxFormatted}
                          className="marginBottom-small"
                        />
                      )}
                    </div>
                  </div>
                )}

                <PlanWithProductsInCartLineItems
                  plan={plan}
                  hasProductsInCart={hasProductsInCart}
                  planName={planName}
                  subscriptionTotalNoTax={planAmount}
                  subscriptionTotal={subscriptionTotal}
                  coupon={coupon}
                  couponText={couponText}
                  couponValueFormatted={
                    !couponValueFormatted ? subscriptionCouponValue : couponValueFormatted
                  }
                  removeCoupon={removeCoupon}
                  couponStyles={couponStyles}
                  subscriptionTaxes={subscriptionTaxes}
                  taxesStyles={taxesStyles}
                  hasSubscriptionCoupon={hasSubscriptionCoupon}
                  standardAndSubscriptionCoupon={standardAndSubscriptionCoupon}
                  subscriptionCreditAdjustments={subscriptionCreditAdjustments}
                />

                <OrderTotalV2
                  text={orderTotalText}
                  totalFormatted={totalPriceWithProduct}
                  badgeText={badgeSavings.amount > 0 && `You saved ${badgeSavings.amountFormatted}`}
                />
                <HourlyPriceDisclaimer
                  suppressTopPadding
                  classes="paddingBottom-small2"
                  showHourlyDisclaimer={showHourlyDisclaimer}
                />
                {affirmPayment && (
                  <AffirmPromo type={AFFIRM_PROMO_TYPES.review} amount={cartPure.breakdown.total} />
                )}
              </div>
            )}

            <PlanInRegularCartLineItems
              plan={plan}
              hasProductsInCart={hasProductsInCart}
              planName={planName}
              subscriptionTotalNoTax={planAmount}
              subscriptionTotal={subscriptionTotal}
              planTotalSubText={planTotalSubText}
              subscriptionTaxFormatted={subscriptionTaxFormatted}
              coupon={coupon}
              couponText={couponText}
              couponValueFormatted={subscriptionCouponValue}
              removeCoupon={removeCoupon}
              hasSubscriptionCoupon={hasSubscriptionCoupon}
              standardAndSubscriptionCoupon={standardAndSubscriptionCoupon}
              subscriptionCreditAdjustments={subscriptionCreditAdjustments}
            />

            {isVirtualVisitRequired && (
              <VirtualSiteVisitCalloutBox className="marginBottom-small2" />
            )}
            <div className={cn(styles.buttonWrapper, styles.tabletDesktop)}>
              <SubmitButton text={submitButtonText} onClick={handleSubmitOrder} />
            </div>
          </PanelV2>
          <HTHUpsellModal isOpen={showHthModal} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

ServiceBreakdownItems.propTypes = {
  services: PropTypes.array,
};

SummaryPageContent.propTypes = {
  addCoupon: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  confirmBooking: PropTypes.func.isRequired,
  couponValueFormatted: PropTypes.string,
  editStage: PropTypes.func.isRequired,
  promoChange: PropTypes.func.isRequired,
  removeCoupon: PropTypes.func.isRequired,
  promoValue: PropTypes.string,
  couponErrors: PropTypes.string,
  isRemoteCart: PropTypes.bool.isRequired,
  breakdownItems: PropTypes.array,
  totalSavings: PropTypes.number,
  totalSavingsFormatted: PropTypes.string,
  orderAdjustmentsNoCoupon: PropTypes.array,
  plan: PropTypes.object,
  planName: PropTypes.string,
  planTotalSubText: PropTypes.string,
  serviceTotalFormatted: PropTypes.string,
  serviceTax: PropTypes.number,
  serviceTaxFormatted: PropTypes.string,
  totalTaxFormatted: PropTypes.string,
  subscriptionTaxFormatted: PropTypes.string,
  subscriptionTotal: PropTypes.string,
  hasProductsInCart: PropTypes.bool,
  hasOnlyProductsInCart: PropTypes.bool,
  card: PropTypes.object,
  consolidatedAvailability: PropTypes.object,
  availability: PropTypes.array,
  coupon: PropTypes.object,
  couponText: PropTypes.string,
  priceBreakdown: PropTypes.string,
  selectedServiceTimes: PropTypes.array,
  subscriptionCoupon: PropTypes.array,
  subscriptionCouponValue: PropTypes.string,
  planAmount: PropTypes.string,
  showPaymentSource: PropTypes.bool,
  preferredTechData: PropTypes.shape({
    techName: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  badgeSavings: PropTypes.shape({
    amount: PropTypes.number,
    amountFormatted: PropTypes.string,
  }),
  subscriptionCreditAdjustments: PropTypes.array,
};

SummaryPageContent.defaultProps = {
  isRemoteCart: false,
  addCoupon: () => {},
  confirmBooking: () => {},
  editStage: () => {},
  promoChange: () => {},
  removeCoupon: () => {},
  breakdownItems: [],
};

export default SummaryPageContent;

/*
  Notes
  * Using object notation instead of immutable .get() notation
  -----
  - cart.availability is a an array of objects where every single time selected is represented as an element in the array -GH June 6, 2020
    example =>  [{"value":"2020-06-07T19:00:00.000Z","display":"Sun. Jun  7 at  9am"},{"value":"2020-06-07T20:00:00.000Z","display":"Sun. Jun  7 at 10am"},...]

  - cart.consolidatedAvailability is an object, where each key represents a date, and it's corresponding value is an
    array of preformatted strings representing the times selected. -GH June 6, 2020
    example => {"2020-06-07":["9am - 8pm"],"2020-06-08":["1pm","6pm"]}
*/
